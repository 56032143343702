<template>
  <div class="row">
    <div class="col-md-12 mt-2">
      <div class="card">
        <h2 class="text-center mt-2">Vendor Agreement</h2>
        <b-row v-if="isError" class="justify-content-center mt-2">
          <b-col lg="10">
            <b-alert variant="danger" show dismissible>
              Opps .. Something is wrong
            </b-alert>
          </b-col>
        </b-row>
        <div class="card-body">
          <div class="form-group row justify-content-center">
            <div class="col-sm-2">
              <b-form-select
                id="currency"
                class="mb-2"
                v-model="status"
                :options="statusOptions"
                required
                @change="onStatusChange"
              ></b-form-select>
            </div>
            <div class="col-sm-6">
              <input
                type="text"
                class="form-control"
                placeholder="type vendor name..."
                @input="debounceSearch"
              />
              <span v-if="typing"> <em> searching... </em> </span>
            </div>
            <div class="col-sm-1">
              <router-link
                class="btn btn-block btn-primary"
                :to="{ path: '/vendor-aggrement/create' }"
              >
                <i class="fa fa-plus"></i>
              </router-link>
            </div>
          </div>
          <div class="row justify-content-end">
            <label for="" class="col-sm-auto">Total : </label>
            <strong class="col-sm-auto">{{ formatNumber(totalRows) }}</strong>
          </div>
          <b-table
            sticky-header="500px"
            :items="vendorAggrement"
            :fields="fields"
            :busy="isLoading"
            :isLoading="isLoading"
            show-empty
            responsive="xl"
            hover
            sort-icon-left
          >
            <template #cell(No)="row">
              {{ (currentPage - 1) * perPage + (row.index + 1) }}
            </template>
            <template #cell(name)="row">
              <div v-if="row.item.vendor_id">
                <router-link
                  :to="{ path: '/vendor/detail/' + row.item.vendor_id }"
                  class="link"
                >
                  {{ row.item.name.replace(/_/g, ' ') }}
                </router-link>
              </div>
              <div v-else>
                {{ row.item.name.replace(/_/g, ' ') }}
              </div>
            </template>
            <template #cell(default_fixed)="row">
              {{
                row.item.default_fixed_amount != null
                  ? row.item.default_fixed_amount
                  : '-'
              }}
            </template>
            <template #cell(default_percentage)="row">
              {{
                row.item.default_percentage_amount != null
                  ? `${toPercent(row.item.default_percentage_amount)} %`
                  : '-'
              }}
            </template>
            <template #cell(formula_name)="row">
              {{ row.item.formula_name ? row.item.formula_name : '-' }}
            </template>
            <template #cell(valid_from)="row">
              {{ row.item.valid_from ? formatDate(row.item.valid_from) : '-' }}
            </template>
            <template #cell(valid_to)="row">
              {{ row.item.valid_to ? formatDate(row.item.valid_to) : '-' }}
            </template>
            <template v-slot:cell(is_active)="row">
              <div>
                <span v-if="row.item.is_active" class="badge badge-success">
                  Active
                </span>
                <span
                  v-else-if="row.item.is_active == false"
                  class="badge badge-danger"
                >
                  In Active
                </span>
                <span v-else class="badge badge-secondary">
                  Not Registered
                </span>
              </div>
            </template>

            <template #cell(action)="row">
              <div class="btn-group" role="group" aria-label="Basic example">
                <div v-if="row.item.vendor_id">
                  <router-link
                    :to="{ path: '/vendor-aggrement/edit/' + row.item.id }"
                    class="link btn btn-info"
                  >
                    <i class="fa fa-pencil"></i>
                  </router-link>
                </div>
                <div v-else>
                  <router-link
                    :to="{ path: '/vendor-aggrement/create' }"
                    class="link btn btn-info"
                  >
                    <i class="fa fa-plus"></i>
                  </router-link>
                </div>
              </div>
            </template>

            <template v-slot:table-busy>
              <div class="text-center text-dark my-2">
                <b-spinner
                  class="align-middle"
                  variant="dark"
                  type="grow"
                  label="Loading .."
                >
                </b-spinner>
                Loading ..
              </div>
            </template>
          </b-table>
        </div>
        <b-row class="justify-content-start ml-1">
          <b-col lg="3">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              aria-controls="my-table"
            />
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import { mapActions, mapState } from 'vuex';
export default {
  name: 'vendorAggrement',
  data() {
    return {
      currentPage: 1,
      perPage: 25,
      filter: {},
      isLoadingSearch: false,
      items_search: [],
      message: '',
      typing: null,
      fields: [
        { key: 'No', label: 'No' },
        { key: 'name', label: 'Vendor Name' },
        { key: 'default_fixed', label: 'Default Fixed' },
        { key: 'default_percentage', label: 'Default Percentage' },
        { key: 'formula_name', label: 'Formula Name' },
        { key: 'valid_from', label: 'Valid From' },
        { key: 'valid_to', label: 'Valid To' },
        { key: 'is_active', label: 'Status' },
        { key: 'action', label: '' },
      ],
      status: '',
      statusOptions: [
        { value: '', text: 'All' },
        { value: 'true', text: 'Active' },
        { value: 'false', text: 'In Active' },
        { value: 'null', text: 'Not Registered' },
      ],
    };
  },
  watch: {
    currentPage: function() {
      this.getAggrement();
    },
    $route: {
      immediate: true,
      handler(to) {
        document.title =
          to.meta.title ||
          'Vendor Aggrement | Content Management System Ebooks Gramedia.com';
      },
    },
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.vendor_revenue.isLoading,
      isError: (state) => state.vendor_revenue.isError,
      totalRows: (state) => state.vendor_revenue.totalRows,
      vendorAggrement: (state) => state.vendor_revenue.items,
    }),
  },
  mounted() {
    this.getAggrement();
  },
  methods: {
    ...mapActions('vendor_revenue', ['fetchVendorAggrement', 'search']),

    getAggrement() {
      let payload = {
        vendor: this.message,
        status: this.status,
        page: this.currentPage,
        limit: this.perPage,
      };
      this.fetchVendorAggrement(payload);
    },

    debounceSearch(event) {
      this.message = null;
      this.typing = 'You are typing';
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.typing = null;
        this.message = event.target.value;
        this.search({
          q: event.target.value,
          page: 1,
          limit: this.perPage,
          status: this.status,
        });
      }, 600);
    },

    formatDate(tgl) {
      return moment(tgl).format('DD MMM YYYY HH:mm:ss');
    },

    formatNumber(value) {
      if (value) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
      }
      return '0';
    },

    toPercent(num) {
      return (parseFloat(num) * 100).toFixed(1);
    },

    onStatusChange() {
      let payload = {
        vendor: this.message,
        status: this.status,
        page: this.currentPage,
        limit: this.perPage,
      };
      this.fetchVendorAggrement(payload);
    },
  },
};
</script>
